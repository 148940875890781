'use client';

import React from 'react';
import { observer } from 'mobx-react';

import { useGlobalContext } from '~/global/Contexts/Global.context';
import { getPreFooterItems } from '~/global/footer/pre-footer.constants';
import { PreFooterItem } from '~/global/footer/Components/PreFooterItem';

import styles from '~/global/footer/Components/PreFooter.module.scss';

export const PreFooter = observer(() => {
	const {
		globalDynamicModel: {
			inHomeDeliveryFeeAmount = 0,
		},
	} = useGlobalContext();

	return (
		<section
			className={styles['pre-footer']}
			data-qa="pre-footer"
			data-tr-link-event-comp-name="footer promos"
		>
			<ul className={styles['pre-footer-items']}>
				{
					getPreFooterItems({ inHomeDeliveryFeeAmount }).map((preFooterItem) => {
						return (
							<PreFooterItem
								key={`pre-footer-item-${preFooterItem.id}`}
								{...preFooterItem}
							/>
						);
					})
				}
			</ul>
		</section>
	);
});
