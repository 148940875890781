import React from 'react';

import type { IFooterLink } from '~/global/footer/Footer.types';

import { FooterLink } from '~/global/footer/Components/FooterLink';

import styles from '~/global/footer/Components/FooterLinksChunk.module.scss';

export const FooterLinksList = ({ links = [] }: {links: IFooterLink[] }) => {
	return (
		<ul className={styles['footer-links-chunk-links']}>
			{
				links.map((link, index = 0) => {
					const { Component, ...linkProps } = link;

					const ComponentToRender = Component || FooterLink;

					return (
						<li key={`footer-link-${index}`}>
							<ComponentToRender {...linkProps} />
						</li>
					);
				})
			}
		</ul>
	);
};
