import type { FunctionComponent, HTMLAttributeAnchorTarget } from 'react';

import type { TextLinkColor } from '~/components/Buttons/Types/constants';

export interface IFooterLink {
	color?: TextLinkColor
	Component?: FunctionComponent<any>,
	copy?: string
	hasUnderline?: boolean
	target?: HTMLAttributeAnchorTarget
	title: string
	url?: string
}

export interface IFooterChunkCommon {
	link: IFooterLink,
	linkList: IFooterLink[],
	title: string
	titleClassName?: string
}

export type FooterChunkList = Omit<IFooterChunkCommon, 'link'>;

export type FooterChunkLink = Omit<IFooterChunkCommon, 'linkList'>;

export type FooterChunk = FooterChunkList | FooterChunkLink;

export const isFooterListChunk = (
	data: FooterChunk,
): data is FooterChunkList => {
	return Object.prototype.hasOwnProperty.call(data, 'linkList');
};

export const isFooterLinkChunk = (
	data: FooterChunk,
): data is FooterChunkLink => {
	return Object.prototype.hasOwnProperty.call(data, 'link');
};
