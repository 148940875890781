import { FooterLegalLinkCookieSettings } from '~/global/footer/Components/FooterLegalLinkCookieSettings';

export const getFooterLegalLinks = (isEngage: boolean = false) => [
	{
		title: 'Privacy Policy',
		url: '/privacy',
	},
	{
		title: 'HR Privacy Policy',
		url: '/hr-privacy',
	},
	...!isEngage ? [{
		Component: FooterLegalLinkCookieSettings,
		title: 'Your Privacy Choices',
	}] : [],
	{
		title: 'Terms of Use',
		url: '/terms-of-use',
	},
	{
		title: 'DMCA',
		url: '/dmca',
	},
	{
		title: 'Accessibility',
		url: '/accessibility',
	},
];
