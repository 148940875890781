'use client';

import React from 'react';

import { kebabCase } from '~/util/kebabCase';
import { s7ContentPath } from '~/global/global.constants';
import { TextButton } from '~/components/Buttons/Components/TextButton';
import { TextLinkColor } from '~/components/Buttons/Types/constants';

export const FooterLegalLinkCookieSettings = ({ title = '' }) => {
	function handleClick() {
		const tsWindow = window as any;

		tsWindow?.OneTrust?.ToggleInfoDisplay?.();
	}

	return (
		<TextButton
			color={TextLinkColor.Gray}
			data-qa={`footer-legal-link-${kebabCase(title)}-link`}
			hasNoUnderline
			onClick={handleClick}
		>
			<img alt="" className="tw-mr-1" src={`${s7ContentPath}/icon_privacy-options`} width="23" height="11" />{title}
		</TextButton>
	);
};
