import React from 'react';

import { FooterEmailSignUp } from '~/global/footer/Components/FooterEmailSignUp';
import { FooterLegal } from '~/global/footer/Components/FooterLegal';
import { FooterLinks } from '~/global/footer/Components/FooterLinks';
import { FooterSocial } from '~/global/footer/Components/FooterSocial';
import { PreFooter } from '~/global/footer/Components/PreFooter';

import styles from '~/global/footer/Components/Footer.module.scss';

export const Footer = ({ showFooterLegalOnly = false }) => {
	return (
		<section className={`${styles['footer-container']} print:tw-hidden`}>
			<div className={styles['footer']}>
				{
					!showFooterLegalOnly && (
						<>
							<PreFooter />
							<FooterEmailSignUp />
						</>
					)
				}
				<footer
					data-tr-link-event-comp-type="footer navigation"
					id="footer"
				>
					{
						!showFooterLegalOnly && (
							<>
								<FooterLinks />
								<FooterSocial />
							</>
						)
					}
					<FooterLegal />
				</footer>
			</div>
		</section>
	);
};
