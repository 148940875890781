import React from 'react';

import { TextLink } from '~/components/Buttons/Components/TextLink';
import { S7Image } from '~/components/Images/S7Image';
import { s7ContentPath } from '~/global/global.constants';

import styles from '~/global/footer/Components/PreFooterItem.module.scss';

export const PreFooterItem = ({
	copy = '',
	imageHeight = 0,
	imageName = '',
	imageWidth = 0,
	linkText = '',
	linkUrl = '',
	title = '',
}) => {
	return (
		<li className={styles['pre-footer-item']}>
			<S7Image
				alt=""
				className={styles['pre-footer-item-image']}
				src={`${s7ContentPath}/${imageName}`}
				height={imageHeight}
				width={imageWidth}
			/>
			<h3 className={styles['pre-footer-item-title']} suppressHydrationWarning>
				{title}
			</h3>
			<p className={styles['pre-footer-item-copy']}>
				{copy}
			</p>
			<TextLink
				href={linkUrl}
			>
				{linkText}
			</TextLink>
		</li>
	);
};
