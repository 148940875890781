import React from 'react';
import NextLink from 'next/link';

import { S7Image } from '~/components/Images/S7Image';
import { s7ContentPath } from '~/global/global.constants';

export const FooterSocialBCorp = () => {
	return (
		<NextLink
			href="/sustainability/certified-b-corp"
		>
			<span className="tw-sr-only">Certified B® Corporation</span>
			<S7Image
				alt=""
				src={`${s7ContentPath}/footer-bCorp`}
				height="84"
				width="50"
			/>
		</NextLink>
	);
};
