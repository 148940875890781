'use client';

import { observer } from 'mobx-react';
import React from 'react';

import { EmailSignUpFormViewState } from '~/email-signup/Components/EmailSignUpForm';
import { useGlobalContext } from '~/global/Contexts/Global.context';

import styles from '~/global/footer/Components/FooterEmailSignUp.module.scss';

export const FooterEmailSignUp = observer(() => {
	const { emailSignUpFormStore = {} } = useGlobalContext();

	return (
		<section
			className={styles['footer-email-sign-up']}
			data-qa="footer-email-sign-up"
			data-tr-link-event-comp-name="sign up for emails"
		>
			<EmailSignUpFormViewState emailSignUpFormStore={emailSignUpFormStore} />
		</section>
	);
});
