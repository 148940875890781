import classNames from 'classnames';
import React from 'react';

import {
	type FooterChunkList, FooterChunkLink, isFooterListChunk, isFooterLinkChunk
} from '~/global/footer/Footer.types';

import { FooterLink } from '~/global/footer/Components/FooterLink';
import { kebabCase } from '~/util/kebabCase';

import styles from '~/global/footer/Components/FooterLinksChunk.module.scss';
import { FooterLinksList } from '~/global/footer/Components/FooterLinksList';

export const FooterLinksChunk = ({ footerChunk }: {footerChunk: FooterChunkList | FooterChunkLink}) => {
	const {
		title = '',
		titleClassName = '',
	} = footerChunk;
	return (
		<section
			data-qa={`footer-links-chunk-${kebabCase((title))}`}
			data-tr-link-event-comp-name={title}
		>
			<h3
				className={
					classNames(styles['footer-links-chunk-title'], {
						[titleClassName]: Boolean(titleClassName)
					})
				}
			>
				{title}
			</h3>
			{ isFooterListChunk(footerChunk) &&
				<FooterLinksList links={footerChunk.linkList} />
			}
			{ isFooterLinkChunk(footerChunk) &&
				<FooterLink {...footerChunk.link} />
			}
		</section>
	);
};
