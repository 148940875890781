import React, { lazy, Suspense } from 'react';

import { getFooterLegalLinks } from '~/global/footer/footer-legal.constants';
import { FooterCopyPageLink } from '~/global/footer/Components/FooterCopyPageLink';
import { FooterLegalLink } from '~/global/footer/Components/FooterLegalLink';
import { isEngage } from '~/global/global.constants';

import styles from '~/global/footer/Components/FooterLegal.module.scss';
import { ClientOnly } from '~/util/Components/ClientOnly';

const FooterCartId = lazy(
	() => import('~/global/footer/Components/FooterCartId'),
);

export const FooterLegal = () => {
	const currentYear = new Date().getFullYear();

	return (
		<section
			className={`${styles['footer-legal']} print:tw-hidden`}
			data-qa="footer-legal"
			data-tr-link-event-comp-name="legal"
		>
			<ul
				className={styles['footer-legal-links']}
				data-qa="footer-legal-links"
			>
				{
					getFooterLegalLinks(isEngage).map((footerLegalLink, index = 0) => {
						const { Component } = footerLegalLink;

						const ComponentToRender = Component || FooterLegalLink;

						return (
							<li
								className={styles['footer-legal-link-container']}
								key={`footer-legal-link-${index}`}
							>
								<ComponentToRender {...footerLegalLink} />
							</li>
						);
					})
				}
			</ul>
			<div
				className={styles['footer-legal-copyright']}
				data-qa="footer-legal-copyright"
				suppressHydrationWarning
			>
				<span className={styles['footer-legal-copyright-text']}>© 2007-{currentYear} Room & Board.  All Rights Reserved.</span>
				{
					isEngage && <FooterCopyPageLink />
				}
				<Suspense fallback={null}>
					<ClientOnly>
						<FooterCartId />
					</ClientOnly>
				</Suspense>
			</div>
		</section>
	);
};
