'use client';

import React, { useRef, useState } from 'react';

import type { RefObject } from 'react';

import { TextButton } from '~/components/Buttons/Components/TextButton';
import { TextLinkColor } from '~/components/Buttons/Types/constants';

import styles from '~/global/footer/Components/FooterCopyPageLink.module.scss';

export const FooterCopyPageLink = () => {
	const [copySuccessTimeout, setCopySuccessTimeout]: any = useState<number>(-1);

	const buttonContainerRef: RefObject<HTMLDivElement> = useRef(null);

	const getPublicUrl = () => {
		const tsWindow = window as any;

		const loc = new URL(tsWindow.location);

		let publicHost = loc.host.replace(/-?engage-?/, '');

		if (publicHost.indexOf('.') === 0) {
			publicHost = `www${publicHost}`;
		}
		return `${loc.protocol}//${publicHost}${loc.pathname}${loc.search}`;
	};

	const buttonClickHandler = () => {
		const tsWindow = window as any;

		let currentLocNode: HTMLElement | null = document.querySelector('#js-current-location');
		let messageNode: HTMLElement | null = document.querySelector('#js-copy-page-link-message');
		const range = document.createRange();

		tsWindow.clearTimeout(copySuccessTimeout);

		// Create the node to contain the data to be copied to clipboard.
		if (!currentLocNode) {
			currentLocNode = document.createElement('span');
			currentLocNode.setAttribute('id', 'js-current-location');
			currentLocNode.classList.add('clipboard-data');
			document.querySelector('body')?.appendChild(currentLocNode);
		}
		// Create the node to contain the status message.
		if (!messageNode && buttonContainerRef.current) {
			messageNode = document.createElement('div');
			messageNode.setAttribute('id', 'js-copy-page-link-message');
			messageNode.classList.add('copy-page-link-message', 'tw-ml-3', 'tw-hidden');
			buttonContainerRef.current.append(messageNode);
		}
		// Select the text for the clipboard to copy.
		currentLocNode.innerText = getPublicUrl();
		if (messageNode) {
			messageNode.innerText = '';
		}
		range.selectNode(currentLocNode);
		tsWindow.getSelection().removeAllRanges();
		tsWindow.getSelection().addRange(range);

		try {
			const successful = document.execCommand('copy');

			if (messageNode) {
				if (successful) {
					messageNode.innerText = 'Copied!';
					messageNode.classList.remove('tw-hidden');
					setCopySuccessTimeout(tsWindow.setTimeout(() => {
						if (messageNode) {
							messageNode.innerText = '';
							messageNode.classList.add('tw-hidden');
						}
					}, 2000));
				} else {
					messageNode.innerText = getPublicUrl();
					messageNode.classList.remove('tw-hidden');
				}
			}
		} catch (err) {
			tsWindow.clearTimeout(copySuccessTimeout);
		}
	};

	return (
		<div
			className={styles['footer-copy-page-link']}
			ref={buttonContainerRef}
		>
			<TextButton
				color={TextLinkColor.Gray}
				data-qa="footer-copy-page-link-button"
				hasNoUnderline
				id="js-copy-page-link-button"
				onClick={buttonClickHandler}
			>
				Copy Page Link
			</TextButton>
		</div>
	);
};
