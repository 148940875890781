import { FooterLinkChat } from '~/global/footer/Components/FooterLinkChat';
import { FooterChunkLink, FooterChunkList } from '~/global/footer/Footer.types';
import { FooterLinkNonSPA } from '~/global/footer/Components/FooterLinkNonSPA';
import { TextLinkColor } from '~/components/Buttons/Types/constants';

export const footerSocialLinks = [
	{
		imageHeight: 28,
		imageName: 'social-pinterest',
		imageWidth: 28,
		title: 'Room & Board Pinterest Board',
		trLinkEventName: 'pinterest',
		url: 'https://pinterest.com/roomandboard',
	},
	{
		imageHeight: 28,
		imageName: 'social-facebook',
		imageWidth: 28,
		title: 'Room & Board Facebook page',
		trLinkEventName: 'facebook',
		url: 'https://facebook.com/roomandboard',
	},
	{
		imageHeight: 28,
		imageName: 'social-tiktok',
		imageWidth: 28,
		title: 'Room & Board TikTok',
		trLinkEventName: 'tiktok',
		url: 'https://tiktok.com/@roomandboardfurniture',
	},
	{
		imageHeight: 28,
		imageName: 'social-instagram',
		imageWidth: 28,
		title: 'Room & Board Instagram',
		trLinkEventName: 'instagram',
		url: 'https://instagram.com/roomandboard',
	},
	{
		imageHeight: 28,
		imageName: 'social-youtube',
		imageWidth: 33,
		linkClassName: 'before:tw-py-1 before:tw-my-1 before:tw-h-3/4',
		title: 'Room & Board YouTube Channel',
		trLinkEventName: 'youtube',
		url: 'https://youtube.com/roomandboard',
	},
];

export const footerLinks: (FooterChunkList | FooterChunkLink)[] = [
	{
		linkList: [
			{
				title: '800.301.9720',
				url: 'tel:+18003019720',
			},
			{
				title: 'Contact Us',
				url: '/contact-us',
			},
			{
				title: 'Store Locations',
				url: '/stores',
			},
			{
				title: 'Free Design Services',
				url: '/free-design-services',
			},
			{
				Component: FooterLinkChat,
				title: 'Live Chat',
			},
		],
		title: 'Get in Touch',
	},
	{
		linkList: [
			{
				title: 'Returns & Exchanges',
				url: '/customer-service/returns-exchanges',
			},
			{
				title: 'Customer Service',
				url: '/customer-service',
			},
			{
				title: 'Delivery Information',
				url: '/customer-service/delivery',
			},
			{
				title: 'Certificate of Insurance (COI)',
				url: '/customer-service/certificate-of-insurance',
			},
			{
				title: 'FAQ',
				url: '/customer-service/faq',
			},
		],
		title: 'Customer Service',
	},
	{
		linkList: [
			{
				title: 'Ideas & Advice',
				url: '/ideas-advice',
			},
			{
				title: 'Free Fabric & Leather Swatches',
				url: '/ideas-advice/explore-materials/fabrics/indoor',
			},
			{
				title: 'Explore Materials',
				url: '/ideas-advice/explore-materials',
			},
			{
				title: 'Customer Photos',
				url: '/ideas-advice/get-inspired/modern-style-gallery',
			},
			{
				target: '_blank',
				title: 'Blog',
				url: 'https://www.roomandboard.com/blog',
			},
		],
		title: 'Design Tools',
	},
	{
		linkList: [
			{
				title: 'Product Care, Assembly & Safety',
				url: '/ideas-advice/product-care',
			},
			{
				title: 'Affirm Financing',
				url: '/affirm-monthly-financing',
			},
			{
				title: 'Digital Catalogs',
				url: '/digital-catalogs',
			},
			{
				title: 'Gift Cards',
				url: '/gift-cards',
			},
			{
				title: 'Product Recalls',
				url: '/product-recalls',
			},
			{
				title: 'CA Transparency Act',
				url: '/about-us/ca-transparency-act',
			},
			{
				title: 'Site Map',
				url: '/catalog/sitemap',
			},
		],
		title: 'Resources',
	},
	{
		linkList: [
			{
				title: 'About Us',
				url: '/about-us',
			},
			{
				Component: FooterLinkNonSPA,
				title: 'Careers',
				url: '/careers',
			},
			{
				title: 'Meet Our Craftspeople',
				url: '/about-us/craftspeople',
			},
			{
				title: 'For Business',
				url: '/business',
			},
			{
				title: 'Sustainability',
				url: '/sustainability',
			},
		],
		title: 'Our Company',
	},
	{
		link: {
			color: TextLinkColor.Blue,
			copy: 'Save 20-50% on discontinued furniture and home decor.',
			hasUnderline: true,
			title: 'Shop Clearance',
			url: '/clearance',
		},
		title: 'Clearance',
		titleClassName: 'tw-text-red',
	},
];
