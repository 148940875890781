import React from 'react';

import { NextJsTextLink } from '~/components/Buttons/Components/NextJsTextLink';
import { TextLinkColor } from '~/components/Buttons/Types/constants';
import { kebabCase } from '~/util/kebabCase';

export const FooterLegalLink = ({
	title = '',
	url = '',
}) => {
	return (
		<NextJsTextLink
			color={TextLinkColor.Gray}
			data-qa={`footer-legal-${kebabCase(title)}-link`}
			hasNoUnderline
			href={url}
		>
			{title}
		</NextJsTextLink>
	);
};
