import React from 'react';

import { FooterLinksChunk } from '~/global/footer/Components/FooterLinksChunk';

import { footerLinks } from '~/global/footer/footer.constants';

import styles from '~/global/footer/Components/FooterLinks.module.scss';

export const FooterLinks = () => {
	return (
		<section
			aria-label="Footer menu"
			className={styles['footer-links']}
			data-qa="footer-links"
		>
			<h2 className="tw-sr-only">Footer</h2>
			{
				footerLinks.map((footerLinksChunk, index) => {
					return (
						<FooterLinksChunk
							footerChunk={footerLinksChunk}
							key={`footer-links-chunk-${index}`}
						/>
					);
				})
			}
		</section>
	);
};
