import React from 'react';

import type { IFooterLink } from '~/global/footer/Footer.types';

import { NextJsTextLink } from '~/components/Buttons/Components/NextJsTextLink';
import { TextLinkColor } from '~/components/Buttons/Types/constants';
import { kebabCase } from '~/util/kebabCase';

import styles from '~/global/footer/Components/FooterLink.module.scss';

export const FooterLink = ({
	color,
	copy = '',
	hasUnderline = false,
	target = '',
	title = '',
	url = '',
}: IFooterLink) => {
	return (
		<>
			{
				copy && (
					<p className={styles['footer-link-copy']}>{copy}</p>
				)
			}
			<NextJsTextLink
				color={color || TextLinkColor.Gray}
				data-qa={`footer-${kebabCase(title)}-link`}
				hasNoUnderline={!hasUnderline}
				href={url}
				{...target && { target }}
			>
				{title}
			</NextJsTextLink>
		</>
	);
};
