import React from 'react';
import NextLink from 'next/link';

import { S7Image } from '~/components/Images/S7Image';
import { s7ContentPath } from '~/global/global.constants';

export const FooterSocialEsop = () => {
	return (
		<NextLink
			href="/about-us"
		>
			<span className="tw-sr-only">100% Employee-owned</span>
			<S7Image
				alt=""
				src={`${s7ContentPath}/careersFooter-esop`}
				height="66"
				width="66"
			/>
		</NextLink>
	);
};
