const formatter = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
	minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
	maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const getPreFooterItems = ({ inHomeDeliveryFeeAmount = 0 }) => [
	{
		id: 'pre-footer-item-1',
		copy: 'Best price every day, no cancellation fees and free returns',
		imageHeight: 45,
		imageName: 'worry-free-check',
		imageWidth: 48,
		linkText: 'See our promise to you',
		linkUrl: '/customer-service',
		title: 'Worry-free Shopping',
	},
	{
		id: 'pre-footer-item-2',
		copy: 'Unlimited items delivered for one flat rate per trip',
		imageHeight: 45,
		imageName: 'rb-delivery-truck',
		imageWidth: 77,
		linkText: 'Get ready for delivery',
		linkUrl: '/customer-service/delivery',
		title: inHomeDeliveryFeeAmount > 0 ? `${formatter.format(inHomeDeliveryFeeAmount)} Full-service Delivery` : 'Full-service Delivery',
	},
	{
		id: 'pre-footer-item-3',
		copy: 'More than 90% of our furniture and decor is made by U.S. artisans',
		imageHeight: 45,
		imageName: 'US_Map',
		imageWidth: 69,
		linkText: 'Meet our craftspeople',
		linkUrl: '/about-us/craftspeople',
		title: 'U.S. Craftsmanship',
	},
	{
		id: 'pre-footer-item-4',
		copy: 'Designing better products with people and the planet in mind',
		imageHeight: 45,
		imageName: 'sustainability-leaf',
		imageWidth: 53,
		linkText: 'Learn about our goals',
		linkUrl: '/sustainability',
		title: 'Committed to Sustainability',
	},
];
