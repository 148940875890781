'use client';

import { observer } from 'mobx-react';
import React, { useRef } from 'react';

import type { RefObject } from 'react';

import { Button } from '~/components/Buttons/Components/Button';
import { ButtonVariant } from '~/components/Buttons/Types/constants';
import { S7Image } from '~/components/Images/S7Image';
import { useGlobalContext } from '~/global/Contexts/Global.context';
import { s7ContentPath } from '~/global/global.constants';

import styles from '~/global/footer/Components/FooterLinkChat.module.scss';

export const FooterLinkChat = observer(({ title = '' }) => {
	const {
		liveChat = {},
		liveChat: {
			showChatIcon = false,
		} = {},
	} = useGlobalContext();

	const buttonRef: RefObject<HTMLButtonElement> = useRef(null);

	function handleClick() {
		// only return focus to this element when it is the original trigger
		liveChat.setOnCloseFocusElement(buttonRef.current);

		liveChat.openChat();
	}

	if (!showChatIcon) {
		return null;
	}

	return (
		<div className={styles['footer-link-chat']}>
			<Button
				data-qa="footer-link-chat"
				onClick={handleClick}
				ref={buttonRef}
				variant={ButtonVariant.HollowTiny}
			>
				<S7Image
					alt=""
					className={styles['footer-link-chat-image']}
					src={`${s7ContentPath}/chat`}
					height="20"
					width="20"
				/>
				<span>
					{title}
				</span>
			</Button>
		</div>
	);
});
